<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="40%"
    >
      <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
        <el-row :gutter="24" v-if="this.editShowCommDity">
          <el-form-item label="备注">
            <el-input v-model="addEditForm.remark" width="20px"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button
          type="primary"
          @click="addFormButton()"
          :loading="loading"
        >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {},
      timer: null,
      ids: null,
      commodityList: [],
      editShowCommDity: false
    };
  },
  methods: {
    show(id) {
      this.dialog = true;
    },
    listDataProductApiButton() {
      listDataProductApi({ attribute: 2, isGround: 2 }).then(res => {
        this.commodityList = res.result;
      });
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.editShowCommDity = false;
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      console.log(this.addEditForm);
      this.addEditForm.uniqueCode = this.addEditForm.commodity;
      createCertificateApi(this.addEditForm).then(res => {
        this.cancelForm();
      });
    },
    //查询详情
    selectByIdButton() {
      selectcertificateByIdApi(this.ids).then(res => {
        this.addEditForm = res.result;
      });
    },
    commodityButton() {},
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    sellTypeButton(value) {}
  }
};
</script>
<style scoped>
</style>