<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="40%"
    >
      <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
        <el-row :gutter="24">
          <el-form-item label="类型">
            <el-radio-group v-model="addEditForm.type" @change="sellTypeButton" prop="type">
              <el-radio :label="1" disabled>常规</el-radio>
              <el-radio :label="2">体验</el-radio>
              <el-radio :label="3">合同</el-radio>
              <el-radio :label="4">内部</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row :gutter="24" v-if="this.editShowCommDity">
          <el-form-item label="选择商品">
            <el-select v-model="addEditForm.commodity" placeholder="请选择" @change="commodityButton">
              <el-option
                v-for="item in commodityList"
                :key="item.uniqueCode"
                :label="item.allName"
                :value="item.uniqueCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
       
        <el-row :gutter="24">
          <el-form-item label="有效时间">
            <el-date-picker
              v-model="addEditForm.refundTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>
        </el-row>
         <el-row :gutter="24" v-if="this.editShowCommDity">
          <el-form-item label="备注">
            <el-input v-model="addEditForm.remark" width="20px"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <!-- @click="$refs.drawer.closeDrawer()" -->
        <el-button
          type="primary"
          @click="addFormButton()"
          :loading="loading"
        >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { createCertificateApi } from "@/api/system/certificate/certificate.js";
import { listDataProductApi } from "@/api/system/product/product.js";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {},
      timer: null,
      ids: null,
      commodityList: [],
      editShowCommDity: false
    };
  },
  methods: {
    show(id, type, sellCode, comboCode) {
      console.log(sellCode);
      if (
        sellCode == null ||
        sellCode == undefined ||
        comboCode == null ||
        comboCode == undefined
      ) {
        this.dialog = false;
        return this.$message.error("请选择有效的一列");
      }
      this.dialog = true;
      if (type == 1) {
        this.title = "手动生成";
        this.listDataProductApiButton();
      }
      if (sellCode == "experience-99999999") {
        this.editShowCommDity = true;
      }
      this.addEditForm.sellCode = sellCode;
      this.addEditForm.comboCode = comboCode;
    },
    listDataProductApiButton() {
      listDataProductApi({ attribute: 2, isGround: 1 }).then(res => {
        this.commodityList = res.result;
      });
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.editShowCommDity = false;
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      console.log(this.addEditForm);
      this.addEditForm.uniqueCode=this.addEditForm.commodity
      createCertificateApi(this.addEditForm).then(res => {
        this.cancelForm();
      });
    },
    //查询详情
    selectByIdButton() {
      selectcertificateByIdApi(this.ids).then(res => {
        this.addEditForm = res.result;
      });
    },
    commodityButton(){}
    ,
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    sellTypeButton(value) {}
  }
};
</script>
<style scoped>
</style>



